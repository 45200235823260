import React, { useState, useEffect } from 'react';
import { Flex, Center, Text, Square, Box, Spacer, Badge, useColorMode, Button, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


const LandingPage = () => {

    let navigate = useNavigate();


    return (
        <Box w='100vw' h='100vh' alignItems='center' justifyContent='center'>
            <Heading>Welcome to omnitalk</Heading>
            <Flex>
                <Button onClick={() => navigate('/login')}>Login</Button>
                <Button onClick={() => navigate('/register')}>Register</Button>


            </Flex>

        </Box>

    );
};

export default LandingPage;