import React from 'react';
import {VStack, Text, Flex, Image, Spacer, HStack, Box} from '@chakra-ui/react';
import GmailMessageContent from "../Gmail/GmailMessageContent";
import SlackMessageContent from "../Slack/SlackMessageContent";

const CombinedMessageContent = ({selectedItemId, items}) => {
    const item = items.find(msg => msg.id === selectedItemId);

    return (
        <Box width={'100%'}>
            {selectedItemId ? (
                <Box width={'100%'}>
                    {item.source === "Gmail" && (
                        <GmailMessageContent selectedItemId={selectedItemId} items={items}/>
                    )}
                    {item.source === "Slack" && (
                        <SlackMessageContent selectedItemId={selectedItemId} items={items}/>
                    )}
                </Box>


            ) : (<></>)}

        </Box>
    );
};

export default CombinedMessageContent;
