import React from 'react';
import {VStack, Text, Box, Flex, Icon, Spacer} from '@chakra-ui/react';
import CombinedMessagePreview from './CombinedMessagePreview';
import {FaChevronDown} from "react-icons/fa6";
import SlackMessagePreview from "../Slack/SlackMessagePreview";
import GmailMessagePreview from "../Gmail/GmailMessagePreview";



const CombinedMessageList = ({items, selectedItemId, setSelectedItemId}) => {
    return (
        <VStack width='100%' height='100%' bg='white' bgColor={''} boxShadow='5px 0 3px -5px rgba(0, 0, 0, 0.1)' zIndex={90}>
            <Flex flexDirection='row' ms='4' mt='6' alignItems='center' alignSelf='start'>
                <Text fontWeight='semibold' fontSize='lg' textColor='black' me='2'>All inboxes</Text>
                <Icon as={FaChevronDown} color='black' boxSize={3}/>
                <Spacer/>
                <Text textColor='black' fontSize='sm' ms='4' fontWeight=''>
                    (Past 24hrs)
                </Text>
            </Flex>
            <VStack width='100%' overflowY='auto' maxHeight='80vh' mt='2' >
                {items.map(item => {

                    const MessagePreviewComponent = item['source'] === "Slack" ? SlackMessagePreview : item['source'] === "Gmail" ? GmailMessagePreview : CombinedMessagePreview;

                    return (
                        <MessagePreviewComponent
                            key={item.id}
                            item={item}
                            isSelected={item.id === selectedItemId}
                            onSelect={() => setSelectedItemId(item.id)}
                        />
                    );
                })}

            </VStack>
            {items.length === 0 && (
                <Text textColor='black' m='4'>No messages found.</Text>
            )}
        </VStack>
    );
};

export default CombinedMessageList;
