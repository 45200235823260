import React, {useState, useEffect} from 'react';
import {
    Flex,
    Center,
    Text,
    Square,
    Box,
    VStack,
    AbsoluteCenter,
    Spacer,
    Icon,
    IconButton,
    Image, HStack, Spinner
} from '@chakra-ui/react';
import {FaChevronDown} from "react-icons/fa6";
import {IoMdRefresh} from "react-icons/io";
import {ChevronDownIcon} from '@chakra-ui/icons'
import SlackMessagePreview from './SlackMessagePreview';
import axios from 'axios';


const SlackMessageList = (props) => {
    const {selectedItemId, setSelectedItemId, slackLoggedIn, slackData, slackLoading} = props

    const signInSlack = async () => {
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/slack/login`;
    }


    return (
        <VStack width='100%' height='100%' bg='white' boxShadow='5px 0 3px -5px rgba(0, 0, 0, 0.1)' zIndex={90}>
            <Flex flexDirection='row' ms='4' mt='6' alignItems='center' alignSelf='start'>
                <Text fontWeight='semibold' fontSize='lg' textColor='black' me='2'>Slack</Text>
                <Icon as={FaChevronDown} color='black' boxSize={3}/>
                <Spacer/>
                <Text textColor='black' fontSize='sm' ms='4' fontWeight=''>
                    (Past 24hrs)
                </Text>
            </Flex>


            {slackLoggedIn && !slackLoading &&
            (<VStack width='100%' mt='2' overflowY='auto' maxHeight='80vh'>
                {slackData.map((item, index) => (
                    <SlackMessagePreview key={item.id} item={item} isSelected={item.id === selectedItemId}
                                         onSelect={() => setSelectedItemId(item.id)}/>
                ))}
            </VStack>)

            }



            {!slackLoggedIn && !slackLoading &&
                (<>
                <Box h='15rem'></Box>
                <Text textColor='black' maxW='100%' mb='2'>It looks like you haven't set up Omnitalk for Slack
                    yet</Text>
                <Flex alignItems='center' bg='white' p='2' px='3' rounded='lg' shadow='lg' cursor='pointer'>
                    <Image src='/icons/slack.png' height='1.4rem' me='2'/>
                    <Text textColor='black' fontWeight='semibold' onClick={signInSlack}>Sign in with
                        Slack</Text>
                </Flex>
                </>)}

             {slackLoggedIn && slackData.length == 0 && !slackLoading &&
                (<>
                <Box h='15rem'></Box>
                <Text textColor='black' maxW='100%' mb='2' >No important messages in Slack found</Text>

                </>)}

             {slackLoading &&
                (
                    <>
                    <Box h='15rem'></Box>
                    <HStack spacing={'3'}>
                        <Text textColor={'black'}>Loading messages</Text>
                        <Spinner color={'black'}/>
                    </HStack>
                    </>
                )
            }






            {/* <Center>
                <Text textColor='black' fontWeight='semibold'>No Messages Available</Text>
            </Center> */}


        </VStack>
    );
};

export default SlackMessageList;