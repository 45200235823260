import React from 'react';
import { Flex, Center, Text, Square, Box, VStack, HStack, Icon, Spacer, Image, Input, Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react';
import { HamburgerIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import AccountInfo from './AccountInfo';

const Navbar = (props) => {
    const { currentInbox, userData } = props

    return (
        <Flex height='4rem' width={'100%'} bg='white' alignItems='center' shadow={'sm'} zIndex={100}>
            <Flex ms='6' alignItems={'center'}>
                <Image height='1.4rem' me='2' src={'./omni.png'}></Image>
                <Text  textColor='black' fontSize='lg' fontWeight='bold'>Omnitalk</Text>

            </Flex>
            <Box width='7rem' />
            {currentInbox &&

                (<Input placeholder={`Search ${currentInbox}`} variant='outline' width='22rem' textAlign='start' textColor='white' size='md' borderColor='gray.300' rounded='xl' />)

            }
            <Spacer />
            <HStack me='5' spacing={4}>
                <QuestionOutlineIcon color='white' boxSize={5} />
                <HamburgerIcon color='white' boxSize={5} />
                {/* Popover wrapper starts here */}
                <Popover placement='bottom-end'>
                    <PopoverTrigger>
                        <HStack cursor='pointer' _hover={{ bg: 'gray.200' }} p='1' px='2' rounded='xl'>
                            <Text textColor='black' me='1' >{userData.name}</Text>

                            <Image
                                borderRadius='full'
                                boxSize='2.25rem'
                                src='https://bit.ly/dan-abramov'
                                alt='Dan Abramov'

                            />
                        </HStack>
                    </PopoverTrigger>
                    <PopoverContent
                        border='none'
                        _focus={{ outline: 'none', boxShadow: 'none' }}
                        _active={{ outline: 'none', boxShadow: 'none' }}
                        _hover={{ outline: 'none', boxShadow: 'none' }}>
                        <AccountInfo userData={userData} />
                    </PopoverContent>
                </Popover>
            </HStack>
        </Flex>





    );
};

export default Navbar;