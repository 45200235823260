import React from 'react';
import { Flex, Center, Text, Square, Box, VStack, HStack, Icon, Image, Spacer, Button, Divider } from '@chakra-ui/react';
import { HiInboxStack } from "react-icons/hi2";
import { SmallAddIcon } from '@chakra-ui/icons'

const InboxSelection = (props) => {
    const { setCurrentInbox, currentInbox, setSelectedItemId } = props


    return (
        <Flex w='100%' h='100%' bg='white'  justifyContent={'center'}>
                {/*<Flex w={'90%'} h={'90%'} flexDirection={'column'} alignItems={'center'} bg='gray.100' rounded='xl' mt='8' shadow='lg'>*/}

                {/*    <VStack width='100%' height='100%'>*/}
                {/*        <Text alignSelf='start' ms='6' fontWeight='semibold' mt='4' textColor='black'>Your Feeds</Text>*/}

                {/*        <HStack mt='3' width='85%' height='2.5rem' rounded='lg' bg={currentInbox == 'Important' ? 'gray.50' : ''} p='2' cursor='pointer' onClick={() => { setCurrentInbox('Important'); setSelectedItemId(null) }} transition="background-color 0.3s ease">*/}
                {/*            <Icon color='black' as={HiInboxStack} boxSize={6} ms='1' me='2' />*/}
                {/*            <Text textColor='black' fontWeight='semibold'>Important</Text>*/}
                {/*        </HStack>*/}
                {/*        <HStack mt='' width='85%' height='2.5rem' rounded='lg' ms='1' p='2' bg={currentInbox == 'Slack' ? 'gray.50' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Slack'); setSelectedItemId(null) }} transition="background-color 0.3s ease">*/}
                {/*            <Image src='/icons/slack.png' height='1.5rem' me='2' />*/}
                {/*            <Text textColor='black' fontWeight='semibold'>Slack</Text>*/}
                {/*        </HStack>*/}

                {/*        <HStack mt='' width='85%' height='2.5rem' rounded='lg' ms='1' p='2' bg={currentInbox == 'Gmail' ? 'gray.50' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Gmail'); setSelectedItemId(null) }} transition="background-color 0.3s ease" b>*/}
                {/*            <Image src='/icons/gmail.png' height='1.1rem' me='2' />*/}
                {/*            <Text textColor='black' fontWeight='semibold'>Gmail</Text>*/}
                {/*        </HStack>*/}
                {/*        <HStack mt='' width='85%' height='2.5rem' rounded='lg' ms='1' p='2' bg={currentInbox == '' ? 'gray.50' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Gmail'); setSelectedItemId(null) }} transition="background-color 0.3s ease" b>*/}
                {/*            <Image src='/icons/outlook.png' height='1.1rem' me='2' />*/}
                {/*            <Text textColor='black' fontWeight='semibold'>Outlook</Text>*/}
                {/*        </HStack>*/}

                {/*    </VStack>*/}

                {/*    <Spacer/>*/}

                {/*    /!*<HStack mt='' width='85%' height='2.5rem' rounded='lg' ms='1' p='2' bg={'gray.400'} cursor='pointer'  transition="background-color 0.3s ease" b>*!/*/}
                {/*    /!*    /!*<Image src='/icons/outlook.png' height='1.1rem' me='2' />*!/*!/*/}
                {/*    /!*    <Text textColor='black' fontWeight='semibold'>Add inbox</Text>*!/*/}
                {/*    /!*</HStack>*!/*/}

                {/*    <Button width='85%' height='2.5rem' variant={'ghost'} mb={'3'} colorScheme={'gray'} leftIcon={<SmallAddIcon />}>*/}
                {/*        Add inbox*/}
                {/*    </Button>*/}
                {/*</Flex>*/}
            <Flex flexDirection={'column'} width={'100%'} height={'100%'} alignItems={'center'} pb={'1'}>
                <VStack width={'100%'} bg={'white'} p={'2'} boxShadow='5px 0 3px -5px rgba(0, 0, 0, 0.1)' >
                    <Text fontWeight='semibold' fontSize='lg' textColor='black' me='2'  alignSelf={'start'} mt={'4'} ms={'3'}>Your Feeds</Text>
                    <HStack mt='3'  width='100%' height='3rem' rounded='md'  bg={currentInbox == 'All' ? 'gray.200' : ''} p='2' cursor='pointer' onClick={() => { setCurrentInbox('All'); setSelectedItemId(null) }} transition="background-color 0.3s ease">
                        <Icon color='black' as={HiInboxStack} boxSize={6} ms='3' me='2' />
                        <Text textColor='black' fontWeight='semibold'>All Inboxes</Text>
                    </HStack>
                    <HStack mt='' width='100%' height='3rem' rounded='md' ms='1' p='2' bg={currentInbox == 'Slack' ? 'gray.200' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Slack'); setSelectedItemId(null) }} transition="background-color 0.3s ease">
                        <Image src='/icons/slack.png' height='1.5rem' me='2'  ms='3' />
                        <Text textColor='black' fontWeight='semibold'>Slack</Text>
                    </HStack>

                    <HStack mt='' width='100%' height='3rem' rounded='md' ms='1' p='2' bg={currentInbox == 'Gmail' ? 'gray.200' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Gmail'); setSelectedItemId(null) }} transition="background-color 0.3s ease" b>
                        <Image src='/icons/gmail.png' height='1.1rem' me='2'  ms='3' />
                        <Text textColor='black' fontWeight='semibold'>Gmail</Text>
                    </HStack>
                    <HStack mt='' width='100%' height='3rem' rounded='md' ms='1' p='2' bg={currentInbox == '' ? 'gray.200' : ''} cursor='pointer' onClick={() => { setCurrentInbox('Gmail'); setSelectedItemId(null) }} transition="background-color 0.3s ease" b>
                        <Image src='/icons/outlook.png' height='1.1rem' me='2'  ms='3' />
                        <Text textColor='black' fontWeight='semibold'>Outlook</Text>
                    </HStack>
                </VStack>
                <Spacer/>
                <Button width='90%'  height='2.5rem' variant={'ghost'} mb={'3'} colorScheme={'gray'} leftIcon={<SmallAddIcon />}>
                        Add inbox
                </Button>


            </Flex>



                 <Divider orientation='vertical' />

        </Flex >
    );
};

export default InboxSelection;