import React from 'react';
import { Center } from '@chakra-ui/react';
function CookiePolicy() {
  return (
      <Center>
        <iframe src="./CookiePolicy.html" style={{width: '90%', height: '100vh', border: 'none'}}/>
      </Center>
  );
}

export default CookiePolicy;