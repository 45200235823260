import React, { useState, useEffect } from 'react';
import {
    Flex,
    Center,
    Text,
    Square,
    Box,
    Spacer,
    Badge,
    useColorMode,
    Button,
    Heading,
    AbsoluteCenter,
    VStack,
    Input,
    InputGroup,
    InputRightElement,
    Image
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Register = () => {

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleRegister = async () => {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, {
            password: password,
            username: email
    }, {
        withCredentials: true
    });

        navigate('/dashboard')

    };

   const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        handleRegister();
        }
    }

    return (
       <Box w='100vw' h='100vh' alignItems='center' justifyContent='center' bgColor={'gray.50'}>
            {/*<Navbar/>*/}

            <Center flexDirection='column'>
                <Box h='6rem' />

                 <Image src={'omni.png'} width={'4rem'} mb={'6'}/>

                <Box width='27rem' shadow='md' borderRadius='xl' px='9' py='9' border={'1px'} borderColor={'gray.200'} bgColor={'white'}>

                    <Text fontSize='3xl' fontWeight='semibold' mb={'1'}>
                        Create an account
                    </Text>
                    <Text mb='12'>
                        Never miss another message again
                    </Text>

                    <VStack spacing={'6'}>
                        <Input mb='4'  placeholder='Email' onKeyDown={handleKeyPress} onChange={(e) => setEmail(e.target.value)} value={email}></Input>
                        <InputGroup size='md'>
                            <Input mb='5' type={show ? 'text' : 'password'}
                                onKeyDown={handleKeyPress} placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password}></Input>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button colorScheme='blue' width='100%' onClick={handleRegister}>Sign In</Button>
                    </VStack>
                     <Text mt="8" width={'100%'} textAlign={'center'}>
                        Already have an account?{" "}
                        <Text as="span" color="blue.500" cursor="pointer" textDecoration="underline" onClick={() => navigate('/login')}>
                            Sign in
                        </Text>
                    </Text>

                </Box>

            </Center>


        </Box >

    );
};

export default Register;