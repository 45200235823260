import React from 'react';
import {
    Flex,
    Center,
    Text,
    Square,
    Box,
    VStack,
    AbsoluteCenter,
    Spacer,
    Icon,
    IconButton,
    HStack,
    Image
} from '@chakra-ui/react';
import { FaChevronDown } from "react-icons/fa6";
import { IoMdRefresh } from "react-icons/io";
import { ChevronDownIcon } from '@chakra-ui/icons'


const GmailMessagePreview = (props) => {
    const { item, isSelected, onSelect } = props


    return (

        <Box h='6rem' w='95%' bg={isSelected ? 'gray.200' : 'white'} px='3' py='3' borderRadius='lg'
            onClick={() => onSelect(item.id)} transition="background-color 0.2s ease"
            cursor="pointer">
            <Flex width={'100%'} alignItems={'center'}>

                {/*<Image*/}
                {/*    maxW={'14%'}*/}
                {/*    borderRadius='full'*/}
                {/*    boxSize='100%'*/}
                {/*    src='https://bit.ly/dan-abramov'*/}
                {/*    alt='Dan Abramov'*/}
                {/*    me='4'/>*/}

                <Box minW={'15%'}>

                    {item.profile_pic?
                        (<Image w='3rem' h={'3rem'} borderRadius={'50%'}  objectFit='cover' src={'/pfp/' + item.profile_pic}></Image>):
                        (
                        <Flex w={'3rem'} h={'3rem'} bgColor={'gray.400'} borderRadius={'full'} alignItems={'center'}
                            justifyContent={'center'} shadow={'md'}>

                            <Text textAlign={'center'} fontWeight={'bold'}
                                fontSize={'lg'} >{item.from_email.charAt(0).toUpperCase()}</Text>

                         </Flex>

                        )

                    }



                </Box>

                <VStack alignItems='start' spacing='0' w={'85%'}>
                    <Flex width='100%'>
                        <Text textColor='black' fontWeight='semibold'
                            lineHeight='1.5' noOfLines={1}>{item.from_email.replace(/<[^>]*>/g, "")}</Text>
                        <Spacer />
                        <Text textColor='black' fontSize='sm'>{item.preview_date}</Text>
                    </Flex>
                    <Text textColor='black' lineHeight='1.5' noOfLines={1} w={'90%'}>{item.subject}</Text>
                    <Flex w={'100%'}>
                        <Text lineHeight='1.4' textColor='gray.600' noOfLines={1}
                            width={'100%'}>{item.body_plain}</Text>
                        <Spacer></Spacer>
                        <Image src='/icons/gmail.png' height='.9rem' alignSelf={'end'} ms={'2'} />
                    </Flex>

                    <Flex width='100%'>
                    </Flex>
                </VStack>
            </Flex>


        </Box>
    );
};

export default GmailMessagePreview;