import React, { useState, useEffect } from 'react';
import { Flex, Center, Text, Square, Box, Spacer, Badge, Image, useColorMode, Button, Heading, AbsoluteCenter, VStack, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./Navbar";


const Login = () => {

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
        password: password,
        username: email
    }, {
        withCredentials: true
    });

    navigate("/dashboard");
    };

     const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
     }


    return (

        <Box w='100vw' h='100vh' alignItems='center' justifyContent='center' bgColor={'gray.50'}>
            {/*<Navbar/>*/}

            <Center flexDirection='column'>
                <Box h='6rem' />

                 <Image src={'omni.png'} width={'4rem'} mb={'6'}/>

                <Box width='27rem' shadow={'md'} border={'1px'} borderColor={'gray.200'} bgColor={'white'} borderRadius='xl' px='9' py='7'>

                    <Text fontSize='3xl' fontWeight='semibold' mb={'1'}>
                        Log in
                    </Text>
                    <Text mb='12'>
                        Stay updated on all of your messages
                    </Text>

                    <VStack spacing={'6'}>
                        <Input mb='4'  placeholder='Email' onKeyDown={handleKeyPress} onChange={(e) => setEmail(e.target.value)} value={email}></Input>
                        <InputGroup size='md'>
                            <Input mb='5' type={show ? 'text' : 'password'}
                                onKeyDown={handleKeyPress} placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password}></Input>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button colorScheme='blue' width='100%' onClick={handleLogin}>Sign In</Button>
                    </VStack>
                     <Text mt="8" width={'100%'} textAlign={'center'}>
                        Don't have an account?{" "}
                        <Text as="span" color="blue.500" cursor="pointer" textDecoration="underline" onClick={() => navigate('/register')}>
                            Sign up
                        </Text>
                    </Text>

                </Box>

            </Center>


        </Box >

    );
};

export default Login;