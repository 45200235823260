import React, { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons'

import { Flex, Center, HStack, Text, Square, Box, Spacer, Badge, useColorMode, Button, Heading, AbsoluteCenter, VStack, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const PricingCard = (props) => {
    const {data, isChecked, handlePurchase} = props
    const priceID = isChecked? data.annual_price_id : data.monthly_price_id

    return (
        <Box minW='22rem' alignItems='center' justifyContent='center' bg={''} border={'1px'} borderColor="gray.300" rounded={'lg'} _hover={{
                bg: "gray.50",
                borderColor: "blue.400",
                transition: "all 0.25s ease",
                cursor: "pointer"
            }}
             onClick={() => handlePurchase(priceID)}
        className="group">

            <Box w={'100%'}   roundedTop={'lg'} px={'9'} pt={'9'} pb={'6'}>
                <VStack w={'100%'} alignItems={'start'}  spacing={'1'}>
                    <Text fontWeight={'semibold'} fontSize={'lg'}>{data.name}</Text>
                    <Heading  size={'xl'}>{isChecked? data.annual_price : data.monthly_price}</Heading>
                    <Text>per user / {isChecked? "year" : "month"}</Text>
                </VStack>

            </Box>

            <Box w={'100%'} h={'60%'} px={'9'} mb={'8'}>
                <VStack w={'100%'} alignItems={'start'}  spacing={'4'} >
                    <HStack><CheckCircleIcon color={'blue.400'}/> <Text fontWeight={'medium'}>{data.b1}</Text></HStack>
                    <HStack><CheckCircleIcon color={'blue.400'}/> <Text fontWeight={'medium'}>{data.b2}</Text></HStack>
                    <HStack><CheckCircleIcon color={'blue.400'}/> <Text fontWeight={'medium'}>{data.b3}</Text></HStack>
                    <HStack><CheckCircleIcon color={'blue.400'}/> <Text fontWeight={'medium'}>{data.b4}</Text></HStack>
                    <Button mt='5'  bgColor={'gray.800'} textColor={'white'} _groupHover={{
                            bgColor: "blue.500",
                            transition: "background-color 0.25s ease"
                        }}>Start your 14 Day Trial</Button>

                </VStack>


            </Box>


        </Box >

    );
};

export default PricingCard;