import React, { useState, useEffect } from 'react';
import { Flex, Center, Text, Square, Box, VStack, AbsoluteCenter, Spacer,HStack, Icon, IconButton, Image, Spinner } from '@chakra-ui/react';
import { FaChevronDown } from "react-icons/fa6";
import { IoMdRefresh } from "react-icons/io";
import { ChevronDownIcon } from '@chakra-ui/icons'
import GmailMessagePreview from './GmailMessagePreview';
import axios from 'axios';



const GmailMessageList = (props) => {
    const { currentInbox, items, selectedItemId, setSelectedItemId, googleLoggedIn, setGoogleLoggedIn, gmailData, setGmailData, gmailLoading } = props

    const signInGoogle = async () => {
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/gmail/login`;
    }


    return (
        <VStack width='100%' height='100%' bg='white' boxShadow='5px 0 3px -5px rgba(0, 0, 0, 0.1)' zIndex={90}>
            <Flex flexDirection='row' ms='4' mt='6' alignItems='center' alignSelf='start'>
                <Text fontWeight='semibold' fontSize='lg' textColor='black' me='2'>{currentInbox}</Text>
                <Icon as={FaChevronDown} color='black' boxSize={3} />
                <Spacer />
                <Text textColor='black' fontSize='sm' ms='4' fontWeight=''>
                    (Past 24hrs)
                </Text>
            </Flex>


            { googleLoggedIn && !gmailLoading &&
                (<VStack width='100%' mt='2' overflowY='auto' maxHeight='80vh'>
                {gmailData.map((item, index) => (
                    <GmailMessagePreview key={item.id} item={item} isSelected={item.id === selectedItemId}
                        onSelect={() => setSelectedItemId(item.id)} />
                ))}
                </VStack>)
            }


            {
                !googleLoggedIn && !gmailLoading &&
                (<>
                    <Box h='15rem'></Box>
                    <Text textColor='black' maxW='100%' mb='2' >You're currently signed out of Gmail for Omnitalk</Text>
                    <Flex alignItems='center' bg='white' p='2' px='3' rounded='lg' shadow='lg' cursor='pointer'>
                        <Image src='/icons/google.png' height='1.5rem' me='2' />
                        <Text textColor='black' fontWeight='semibold' onClick={signInGoogle}>Sign in with Google</Text>
                    </Flex>
                </>)
            }

             {googleLoggedIn && gmailData.length == 0 && !gmailLoading &&
                (<>
                <Box h='15rem'></Box>
                <Text textColor='black' maxW='100%' mb='2' >No important messages in Gmail found    </Text>

            </>)}

            {gmailLoading &&
                (
                    <>
                    <Box h='15rem'></Box>
                    <HStack spacing={'3'}>
                        <Text textColor={'black'}>Loading messages</Text>
                        <Spinner color={'black'}/>
                    </HStack>
                    </>
                )
            }






        </VStack >
    );
};

export default GmailMessageList;