import React from 'react';
import {Flex, Center, Text, Square, Box, VStack, HStack, Image, Spacer} from '@chakra-ui/react';

const SlackMessageContent = (props) => {
    const {selectedItemId, items} = props
    const item = items.find(email => email.id === selectedItemId);

    return (
        selectedItemId != null ? (<VStack mt='8' ps={'6'} pe={'5'}  bg='white' alignItems='start'>

            <Text fontSize='2xl' fontWeight='bold' textColor=' black'>Slack Message</Text>

            <Flex w='100%' >


                <HStack mt='3'>
                    <Flex w={'3rem'} h={'3rem'} bgColor={'gray.400'} borderRadius={'full'} alignItems={'center'}
                          justifyContent={'center'} shadow={'md'} me={'1'}>

                        <Text textAlign={'center'} fontWeight={'bold'}
                              fontSize={'lg'}>{item.sender.charAt(0).toUpperCase()}</Text>


                    </Flex>

                    <VStack alignItems='start'>
                        <Text fontSize='lg' fontWeight='semibold' textColor=' black' lineHeight='1'>{item.sender}</Text>
                        <Text textColor='gray.700' lineHeight='1'># {item.channel}</Text>

                    </VStack>

                </HStack>
                <Spacer/>

                <HStack>
                    <Text textColor='gray.700'>{item.internal_date}</Text>
                </HStack>

            </Flex>
            <Text fontSize='lg' textColor='black' mt='4' textAlign='start' w='100%' >{item.message}</Text>


        </VStack>) : (<></>)


    );
};

export default SlackMessageContent;