import React, {useState, useEffect} from 'react';
import {
    Flex,
    Center,
    Text,
    Square,
    Box,
    VStack,
    AbsoluteCenter,
    Spacer,
    Icon,
    IconButton,
    Image,
    HStack,
    Button
} from '@chakra-ui/react';
import {FaChevronDown} from "react-icons/fa6";
import {IoMdRefresh} from "react-icons/io";
import {ChevronDownIcon} from '@chakra-ui/icons'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';


const AccountInfo = (props) => {
    const {userData} = props
    const navigate = useNavigate();


    const handle_logout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/logout`, {withCredentials: true});
            navigate('/login'); // Redirect to base URL if not logged in

        } catch (error) {
            console.error('Error logging out:', error);
        }

    }


    return (
        <Box width='20rem' shadow='lg' rounded='xl' alignItems='center' pb='8'>
            <Center>
                <VStack spacing='0'>

                    <Image
                        borderRadius='full'
                        mt='12'
                        boxSize='6rem'
                        src='https://bit.ly/dan-abramov'
                        alt='Dan Abramov'
                        mb='6'

                    />
                    <Text fontSize='xl' mb='1' fontWeight='semibold'>{userData.name}</Text>
                    <Text fontSize='lg' fontWeight='' mb={'1'}>Omnitalk Professional Plan</Text>

                    <Text fontSize='lg' fontWeight='' mb={'8'}>{userData.email}</Text>

                    <HStack>
                        <Button variant={'ghost'}>Manage</Button>
                        <Button variant={'ghost'} onClick={handle_logout}>Sign out</Button>
                    </HStack>
                </VStack>


            </Center>


        </Box>

    );
};

export default AccountInfo;