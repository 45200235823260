import React, { useState, useEffect } from 'react';
import { Flex, Center, Text, Square, Box, Spacer, Switch, Badge, HStack, useColorMode, Button, Heading, AbsoluteCenter, VStack, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from "../Navbar";
import PricingCard from "./PricingCard";
import { css } from '@emotion/react';


const Pricing = () => {

    const [isChecked, setIsChecked] = useState(false);


    const userTestData = {
    'name': 'Daniel Miller',
    'email': 'daniel.harris@omnitalk.xyz'
    }

    const professionalPlan = {
        'name': 'Professional',
        'monthly_price': '$39',
        'annual_price': '$399',
        'b1': 'Connect up to two channels',
        'b2': 'Receive Prioritized Messages',
        'b3': 'Dynamic Learning Algorithm',
        'b4': 'User Friendly Interface',
        'monthly_price_id': 'price_1PJ6AIC2alDLKGOXNIlIOQSu',
        'annual_price_id': 'prof_annual'
    }

    const enterprisePlan = {
        'name': 'Enterprise',
        'monthly_price': '$59',
        'annual_price': '$699',
        'b1': 'Connect unlimited channels',
        'b2': 'Receive Prioritized Messages',
        'b3': 'Dynamic Learning Algorithm',
        'b4': 'User Friendly Interface',
        'monthly_price_id': 'ent_monthly',
        'annual_price_id': 'ent_annual'
    }

    const handlePurchase = async (priceID) => {
        const response = await axios.post(`http://127.0.0.1:5000/create-checkout-session`, {priceID: priceID }, {
        withCredentials: true
        });

        // console.log(response)

        window.location.href = response.data.url;

    }

    return (
        <Flex w='100vw' h='100vh' alignItems='center' justifyContent={'start'} bg={''} flexDirection={'column'}>
            <Navbar currentInbox={null} userData={userTestData}/>
            <Box borderRadius='2rem' border={'1px solid #CBD5E0'} alignItems='center' justifyContent='center' px='3' py='1' display="inline-flex" mb={'3'} mt={'10'}>
                <Text textAlign='center' textColor={'black'}>Pricing & Plans</Text>
            </Box>

            <Text mb={'4'} fontSize='5xl' fontWeight={'500'}>
                Activate your Omnitalk plan

            </Text>
            <HStack alignItems={'center'} justifyContent={'center'} mb={'8'}>
                <Text>Monthly</Text>
                <Switch isChecked={isChecked} onChange={() => setIsChecked(!isChecked)} css={css`
                .chakra-switch__track {
                  background-color: black;
                    border: black; 
                },
                .chakra-switch__thumb {
          background-color: white;
        },
                    
              `}/>
                <Text>Yearly</Text>
            </HStack>

            <HStack spacing={'10'}>
                <PricingCard data={professionalPlan} isChecked={isChecked} handlePurchase={handlePurchase}/>
                <PricingCard data={enterprisePlan} isChecked={isChecked} handlePurchase={handlePurchase}/>
            </HStack>


        </Flex >

    );
};

export default Pricing;