import React from 'react'; // Ensure React is imported
import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, browserHistory, Navigate } from "react-router-dom";
import MainPage from './MainPage';
import theme from './theme';
import { FaLandMineOn } from 'react-icons/fa6';
import LandingPage from './LandingPage';
import Login from './Login'
import Register from './Register';
import CookiePolicy from "./Privacy/CookiePolicy";
import PrivacyPolicy from "./Privacy/PrivacyPolicy";
import Pricing from "./Pricing/Pricing"

function App() {


  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<MainPage />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing/>} />






        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
