import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Center, Text, Square, Box, Spacer, Badge, useColorMode} from '@chakra-ui/react';
import InboxSelection from './Inboxes/InboxSelection';

import GmailMessageList from './Gmail/GmailMessageList';
import GmailMessageContent from './Gmail/GmailMessageContent';

import SlackMessageList from './Slack/SlackMessageList';
import SlackMessageContent from './Slack/SlackMessageContent';


import Navbar from './Navbar';
import axios from 'axios';
import CombinedMessageList from './Important/CombinedMessageList';
import CombinedMessageContent from "./Important/CombinedMessageContent";


const MainPage = () => {
    const [selectedItemId, setSelectedItemId] = useState(null); // Track the selected item ID
    const [googleLoggedIn, setGoogleLoggedIn] = useState(false);
    const [slackLoggedIn, setSlackLoggedIn] = useState(false);

    const [gmailData, setGmailData] = useState([])
    const [slackData, setSlackData] = useState([])
    const [importantData, setImportantData] = useState([]);

    const [slackLoading, setSlackLoading] = useState(false)
    const [gmailLoading, setGmailLoading] = useState(false)



    const [userData, setUserData] = useState([])

    const navigate = useNavigate();


    const [currentInbox, setCurrentInbox] = useState(() => {
        // Retrieve the saved inbox from localStorage, defaulting to "Important" if not found
        return localStorage.getItem('currentInbox') || "All";
    });

    useEffect(() => {
        // Save the current inbox to localStorage whenever it changes
        localStorage.setItem('currentInbox', currentInbox);
    }, [currentInbox]);




    const userTestData = {
        'name': 'Daniel Miller',
        'email': 'daniel.miller@omnitalk.xyz'
    }




    // TODO: Add separate try/catch blocks for auth and fetch
    const checkGoogleAuthStatus = async () => {
        try {
            setGmailLoading(true)
            // Make sure user has logged into Google
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/gmail/auth_status`, {withCredentials: true});
            // Bulk fetch last ten emails
            const resp = await axios.get(`${process.env.REACT_APP_SERVER_URL}/gmail/fetch_emails`, {withCredentials: true});

            setGoogleLoggedIn(true)

            console.log("GOOGLE DATA BELOW")
            console.log(resp.data)
            setGmailData(resp.data)

            // Start interval to fetch new emails
            // setInterval(fetchGmailData, 1000); UNCOMMENT LATER

        } catch (error) {
            console.error('Error checking auth status:', error);
            setGoogleLoggedIn(false)
        } finally {
            setGmailLoading(false)
        }
    };

    // TODO: Add separate try/catch blocks for auth and fetch
    const checkSlackAuthStatus = async () => {
        try {
            setSlackLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/slack/auth_status`, {withCredentials: true});

            if (response.status === 200) {
                setSlackLoggedIn(true);

                // Fetch last ten Slack messages
                const slackResp = await axios.get(`${process.env.REACT_APP_SERVER_URL}/slack/fetch_recent_messages`, {withCredentials: true});

                console.log("SLACK DATA BELOW")
                console.log(slackResp.data)
                setSlackData(slackResp.data); // setSlackData(slackTestData);

                // Start interval to fetch new messages
                // setInterval(fetchSlackData, 1000); UNCOMMENT LATER
            }
        } catch (error) {
            console.error('Error checking Slack auth status:', error);
            setSlackLoggedIn(false);
        } finally {
            setSlackLoading(false)
        }
    };

    // TODO: Add separate try/catch blocks for auth and fetch
    const checkImportantData = async () => {
        try {
            // Fetch important messages
            const impResp = await axios.get(`${process.env.REACT_APP_SERVER_URL}/fetch_important_messages`, {withCredentials: true});

            console.log("IMPORTANT DATA BELOW")
            console.log(impResp.data)

            setImportantData(impResp.data);

            // Load test data from json file
          //   fetch('/combinedTestData.json') // Adjust the path if necessary
          // .then(response => response.json())
          // .then(jsonData => setImportantData(jsonData))
          // .catch(error => console.error('Error loading the JSON file:', error));


            // console.log("HEREEEEE", impResp.data[0].source)
        } catch (error) {
            console.error('Error fetching important messages:', error);
        }
    };

    const checkLoggedIn = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/is_logged_in`, {withCredentials: true});
            if (!response.data.logged_in) {
                navigate('/login'); // Redirect to base URL if not logged in
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            navigate('/login');
        }
    };

    let ran = false
    useEffect(() => {
        // This function checks the user's auth status and starts an interval to regularly fetch updates

        if (!ran) {
            checkLoggedIn();
            checkGoogleAuthStatus();
            checkSlackAuthStatus();
            checkImportantData()
            ran = true
        }


    }, []);

    const fetchGmailData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/gmail/updates`, {withCredentials: true});
            setGmailData(gmailData => gmailData.concat(response.data))  // New code to append
            console.log("GMAIL DATA BELOW")
            console.log(gmailData)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };


    const fetchSlackData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/slack/updates`, {withCredentials: true});
            setSlackData(slackData => slackData.concat(response.data))  // New code to append
            console.log("SLACK DATA BELOW")
            console.log(slackData)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };


    return (
        <Flex bg='white' w='100vw' h='100vh' flexDirection='column'>
            <Navbar currentInbox={currentInbox} userData={userTestData}/>
            <Flex color='white' width='100vw' flex="1">
                <Center w='14rem' bg='white'>
                    <InboxSelection setCurrentInbox={setCurrentInbox} currentInbox={currentInbox}
                                    setSelectedItemId={setSelectedItemId}/>
                </Center>
                <Square w='27rem' h='100%' bg=' white'>
                    {currentInbox === "All" && (
                        <CombinedMessageList items={importantData} selectedItemId={selectedItemId}
                                             setSelectedItemId={setSelectedItemId}/>

                    )}

                    {currentInbox == "Gmail" ?
                        (<GmailMessageList selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId}
                                           currentInbox={currentInbox} items={gmailData}
                                           googleLoggedIn={googleLoggedIn} setGoogleLoggedIn={setGoogleLoggedIn}
                                           gmailData={gmailData} setGmailData={setGmailData} gmailLoading={gmailLoading}/>) :
                        (<></>)
                    }

                    {currentInbox == "Slack" ?
                        (<SlackMessageList selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId}
                                           slackLoggedIn={slackLoggedIn} slackData={slackData} slackLoading={slackLoading}/>) :
                        (<></>)
                    }
                </Square>
                <Box flex='1'>
                    {currentInbox === "All" && (
                        <CombinedMessageContent selectedItemId={selectedItemId} items={importantData}/>
                    )}
                    {currentInbox === "Gmail" && googleLoggedIn &&  (
                        <GmailMessageContent selectedItemId={selectedItemId} items={gmailData}/>
                    )}
                    {currentInbox === "Slack" && slackLoggedIn &&   (
                        <SlackMessageContent selectedItemId={selectedItemId} items={slackData}/>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};

export default MainPage;