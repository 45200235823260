import React from 'react';
import {Flex, Center, Text, Square, Box, VStack, HStack, Image, Spacer} from '@chakra-ui/react';
import EmailFrame from "./EmailFrame";

const GmailMessageContent = (props) => {
    const {selectedItemId, items} = props
    const item = items.find(email => email.id === selectedItemId);
    let customHtml;
    if (item) {
      customHtml = `
        <style>
          body {
            font-family: 'Arial', sans-serif;
            line-height: 1.5;
            font-size: 17px;
          </style>
          ${item.body_html}
      `;
    }

    return (
        selectedItemId != null ?
            (<VStack ps={'6'} pe='5' pt='8' bg='white' alignItems='start' overflowY='auto' maxH={'90vh'} width={'100%'}>
                <Text fontSize='2xl' fontWeight='bold' textColor=' black'>{item.subject}</Text>


                <Flex w='100%' mb={'5'}>


                    <HStack mt='3'>

                        {item.profile_pic?
                            (<Image w='3rem' h={'3rem'} borderRadius={'50%'}  objectFit='cover' src={'/pfp/' + item.profile_pic}></Image>)
                            :
                            (
                             <Flex w={'3rem'} h={'3rem'} bgColor={'gray.400'} borderRadius={'full'} alignItems={'center'}
                          justifyContent={'center'} shadow={'md'} me={'1'}>

                                <Text textAlign={'center'} fontWeight={'bold'}
                                      fontSize={'lg'}>{item.from_email.charAt(0).toUpperCase()}</Text>


                             </Flex>


                            )
                        }


                        <VStack alignItems='start'>
                            <Text fontSize='lg' fontWeight='semibold' textColor=' black'
                                  lineHeight='1'>{item.from_email}</Text>
                            <Text textColor='black' lineHeight='1'>To: {item.to_email}</Text>

                        </VStack>

                    </HStack>
                    <Spacer/>

                    <HStack>
                        <Text textColor='gray.700'>{item.internal_date}</Text>
                    </HStack>
                </Flex>

                <iframe
                    srcDoc={customHtml}
                    style={{width: '100%', height: '100vh', border: 'none'}}
                    title="Email Content">
                </iframe>
            </VStack>) : (<></>)


    );
};

export default GmailMessageContent;