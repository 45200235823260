import React from 'react';
import {Box, VStack, HStack, Text, Flex, Spacer, Image} from '@chakra-ui/react';

const CombinedMessagePreview = (props) => {
    const {item, isSelected, onSelect} = props;

    // Determine which icon to display based on the message's source
    const sourceIcon = item.source === 'Gmail' ? '/icons/gmail.png' : '/icons/slack.png';

    return (

        <Box h='6rem' w='95%' bg={isSelected ? 'gray.200' : 'gray.100'} p='3' borderRadius='lg'
             onClick={() => onSelect(item.id)} transition="background-color 0.2s ease"
             cursor="pointer">
            <HStack h='100%' alignItems='center'>
                <Image
                    borderRadius='full'
                    boxSize='3.5rem'
                    src='https://bit.ly/dan-abramov' // Placeholder for a user or service avatar
                    alt={item.source}
                    me='2'
                />

                <VStack alignItems='start' spacing='0' width='100%'>
                    <Flex width='100%'>
                        <Text textColor='black' fontWeight='semibold' lineHeight='1.5'>{item.from}</Text>
                        <Spacer/>
                        <Text textColor='black' fontSize='sm'>{new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</Text>

                    </Flex>
                    <Text textColor='black' lineHeight='1.5'>{item.text}</Text>
                    <Flex width='100%'>
                        <Text lineHeight='1.4' textColor='gray.600'>{item.snippet?.slice(0, 30) ?? ''}...</Text>
                        <Spacer/>
                        <Box height={'1rem'}></Box>
                        {/*<Image src={sourceIcon} height='1rem'/>*/}
                    </Flex>
                </VStack>
            </HStack>
        </Box>


    );
};

export default CombinedMessagePreview;